<template>
  <div
    :class="[
      { 'in-live-preview': inLivePreview },
      { 'animated-container': !disabled },
    ]"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Only animate when ready
     * when FALSE the css animation classes
     * are still applied
     */
    ready: {
      type: Boolean,
      default: true,
    },

    /**
     * How many seconds
     * GSAP should wait initially
     * until it plays the animatin
     */
    initialDelay: {
      type: Number,
      default: null,
    },

    /**
     * Disable all animations
     * and make everything visible when necessary
     *
     * (like there were no animations set)
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    showMarkers: {
      type: Boolean,
      default: false,
    },

    triggerStart: {
      type: String,
      default: 'top center',
    },
  },

  data() {
    return {
      tl: null,
      inLivePreview: false,
    }
  },

  mounted() {
    this.checkIfIsInLivePreview()

    this.initAnimation()
  },

  methods: {
    initAnimation() {
      // do not animate when...
      if (this.inLivePreview || !this.$el) {
        return
      }

      // otherwise animate that shit
      // all further animations will be added to this gsap Timeline
      let q = this.$gsap.utils.selector(this.$el)

      this.$gsap.delayedCall(0.5, () => {
        this.tl = this.$gsap
          .timeline({
            scrollTrigger: {
              trigger: this.$el,
              start: this.triggerStart,
              markers: this.showMarkers,
            },
          })
          .to(q('.fade-in'), {
            opacity: 1,
            y: 0,
            duration: 0.8,
            stagger: 0.15,
            ease: 'power2.out',
          })
      })
    },

    checkIfIsInLivePreview() {
      this.inLivePreview = !!this.$route.query['live-preview']
    },
  },
}
</script>

<style lang="scss">
.animated-container:not(.in-live-preview) {
  .fade-in {
    @apply opacity-0 translate-y-10;
  }
}
</style>
